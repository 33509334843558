const serialize = (obj, key) => {
  const text = JSON.stringify(obj);
  return [...text]
    .map((x, i) =>
      (x.codePointAt() ^ key.charCodeAt(i % key.length) % 255)
        .toString(16)
        .padStart(2, '0'),
    )
    .join('');
};

const deserialize = (text, key) => {
  if (!text) return text;
  return JSON.parse(
    String.fromCharCode(
      ...text
        .match(/.{1,2}/g)
        .map((e, i) => parseInt(e, 16) ^ key.charCodeAt(i % key.length) % 255),
    ),
  );
};

const InMemoryCache = {};

const FastCache = name => {
  const v = 1;
  const key = `__mycnc__${v}_${name}`;

  const get = () => {
    if (window?.localStorage) {
      return deserialize(window.localStorage.getItem(key) || '', key);
    }
    return InMemoryCache[key] || '';
  };

  const set = value => {
    if (window?.localStorage) {
      window.localStorage.setItem(key, serialize(value, key));
    } else {
      InMemoryCache[key] = value;
    }
    return value;
  };

  const remove = () => {
    if (window?.localStorage) {
      window.localStorage.removeItem(key);
    }
  };

  return { get, set, remove };
};

export default FastCache;
