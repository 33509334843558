import ReactDOM from 'react-dom';

// Providers
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Constants
import theme from './theme';
import apolloClient from 'apollo';

// Pages
import Showcase from 'pages/Showcase';

// Utilities
import history from 'constants/config/history';

// Shims
import './shims';

// Css
import './index.css';

// Component
import App from './App';

const render = Component => {
  ReactDOM.render(
    <Router history={history}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Switch>
                <Route path="/showcase" component={Showcase} />
                <Route component={Component} />
              </Switch>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Router>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
