import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import useQuery from 'hooks/useQuery';
import Context from './Context';
import Loader from 'components/Loader';
import Logger from 'utilities/logger';
import FastCache from 'utilities/fastCache';

const Provider = ({ children }) => {
  const cache = FastCache('SystemConfiguration');
  const cached = cache.get();
  const [loading, setLoading] = useState(!cached);
  const [configuration, setConfiguration] = useState(cached || {});
  const logger = Logger('CONFIGURATION');

  const getConfiguration = useQuery('configuration', {
    scopes: null,
    response: res => {
      const configuration = res.data.configuration;
      const [key, ...value] = configuration.split('+');
      const data = CryptoJS.AES.decrypt(value.join('+'), key).toString(
        CryptoJS.enc.Utf8,
      );
      logger.debug('Configuration', data);
      return JSON.parse(data);
    },
  });

  useEffect(() => {
    logger.debug('Cached Data', { cached });
    getConfiguration()
      .then(config => {
        setConfiguration(config);
        cache.set(config);
        setLoading(false);
      })
      .catch(err => {
        logger.error('No Configuration', err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loader fullScreen withLogo />;
  }

  const value = (name, defaultValue) => {
    if (!configuration[name]) {
      logger.error('Variable does not exists', name);
    }
    return configuration[name] || defaultValue;
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
