import { useState, useRef, useEffect, useCallback } from 'react';
import get from 'lodash.get';
import debounce from 'lodash.debounce';
import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Popper from '@mui/material/Popper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Close } from '@mui/icons-material';
import ContentBox from 'components/CncUI/ContentBox';
import Typography from 'components/CncUI/Typography';
import TextField from 'components/CncUI/TextField';
import TextFieldZip from 'components/CncUI/TextField/TextFieldZip';
import Dropdown from 'components/CncUI/Dropdown';
import Button from 'components/CncUI/Button';
import Modal from 'components/CncUI/Modal';
import Link from 'components/CncUI/Link';
import loqate from 'providers/Loqate';
import States from 'constants/componentData/states';

const useStyles = makeStyles(({ palette }) => ({
  content: {
    maxHeight: '400px',
    overflow: 'hidden',
  },
  container: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
  header: {
    display: 'block',
    '& span': {
      margin: 0,
      display: 'block',
    },
  },
  item: {
    background: palette.cncUI.default.table,
    zIndex: 4,
    '&:hover': {
      background: palette.cncUI.primary.gray['+9'],
    },
  },
}));

const DEFAULT_FORM = {
  number: '',
  unit: '',
  street: '',
  city: '',
  province: '',
  postal: '',
  country: 'US',
  countryName: '',
};

const SearchFieldAddress = props => {
  const classes = useStyles();
  const {
    id = 'SearchFieldAddress',
    className,
    placeholder,
    FieldProps = {},
    onAction,
    countries = 'US,CA',
    items = 10,
    showOther = false,
  } = props;
  const { label = 'Search ...', onSelect, value = '', onClear = null } = props;
  const { onBlur = () => {}, onEmpty = () => {}, disabled = false } = props;
  const [search, setSearch] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [show, showModal] = useState(false);
  const [form, setForm] = useState(DEFAULT_FORM);
  const inputEl = useRef(null);

  const COUNTRIES = [
    { value: 'US', label: 'United States', iso3: 'USA' },
    { value: 'CA', label: 'Canada', iso3: 'CAN' },
    ...(showOther ? [{ value: 'OT', label: 'Other', iso3: 'OTR' }] : []),
  ];

  const LABELS = {
    US: {
      province: 'State',
      city: 'City',
      postal: 'Zip Code',
      number: 'Street Number',
    },
    CA: {
      province: 'Province',
      city: 'Municipality',
      postal: 'Postal Code',
      number: 'Civic Number',
    },
    ...(showOther
      ? {
          OT: {
            city: 'City',
            postal: 'Postal Code',
            number: 'Street Number',
          },
        }
      : {}),
  };

  useEffect(() => {
    if (search !== value) {
      setSearch(value);
    }
  }, [value]);

  const loqateSearch = (value, items, countries) => {
    loqate
      .search(value, undefined, items || 10, countries)
      .then(res => setSuggestions(get(res, 'body.Items', [])))
      .catch(() => setSuggestions([]));
  };

  const addressSearch = useCallback(debounce(loqateSearch, 500), []);

  const update = data => setForm(old => ({ ...old, ...data }));
  const openModal = () => showModal(true);
  const closeModal = () => showModal(false);
  const clearForm = () => setForm(DEFAULT_FORM);
  const handle = type => e => {
    if (type === 'country') {
      update({ province: '' });
    }
    update({ [type]: e.target.value });
  };

  const stateOptions = States[form.country];
  const validForm = Object.keys(form).every(key =>
    key === 'unit'
      ? true
      : form.country === 'OT' && key === 'province'
      ? true
      : form.country !== 'OT' && key === 'countryName'
      ? true
      : Boolean(form[key]),
  );
  const accept = () => {
    const line = `${form.number} ${form.street} ${form.unit}`;
    const country = COUNTRIES.find(c => c.value === form.country) || {};
    const province = stateOptions?.find(s => s.value === form.province) || {};
    setSearch(
      `${line || ''}, ${form.city || ''}, ${form.province ||
        ''} ${form.postal || ''}${
        form.countryName ? ', ' + form.countryName : ''
      }`,
    );
    if (onSelect) {
      onSelect({
        Line1: line,
        Line2: '',
        Line3: '',
        BuildingNumber: form.number,
        SubBuilding: form.unit,
        Street: form.street,
        City: form.city,
        Province: province.value,
        ProvinceCode: province.value,
        ProvinceName: province.label,
        PostalCode: form.postal,
        CountryName: form.countryName || country.label,
        CountryIso2: form.country,
        CountryIso3: country.iso3,
      });
    }
    clearForm();
    closeModal();
  };

  const saveAddress = Id => {
    loqate
      .get(Id)
      .then(res => {
        const selected = get(res, 'body.Items[0]', {});
        if (onSelect) onSelect(selected);
        const {
          Line1 = '',
          City = '',
          Province = '',
          PostalCode = '',
          CountryName = '',
        } = selected;
        setTimeout(() =>
          setSearch(
            `${Line1 || ''}, ${City || ''}, ${Province || ''} ${PostalCode ||
              ''}${CountryName ? ', ' + CountryName : ''}`,
          ),
        );
      })
      .catch(() => {})
      .finally(() => setSuggestions([]));
  };

  const onChange = e => {
    const { value } = e.target;
    setSearch(value);

    if (value.length < 1) {
      if (onEmpty) onEmpty();
      return;
    }

    // getSuggestions from provider
    // and set suggestions in state
    if (value.length > 3) {
      addressSearch(value, items, countries);
    }
  };

  const selectSuggestion = address => {
    if (address.Type === 'Address') {
      saveAddress(address.Id);
    } else {
      loqate
        .search('', address.Id)
        .then(res => setSuggestions(get(res, 'body.Items', [])))
        .catch(() => setSuggestions([]));
    }
  };

  const renderAddressSuggestions = () => {
    return (
      <ContentBox dense className={classes.content}>
        <div className={classes.container}>
          <ListItem key={`address-header`}>
            <ListItemText
              primary="Addresses"
              secondary={
                <span className={classes.header}>
                  <span>Please select your address</span>
                  <Link
                    id="SearchFieldAddress-CantFindLink"
                    onClick={() => {
                      if (onAction) {
                        onAction();
                      } else {
                        openModal();
                      }
                      setSearch('');
                      setSuggestions([]);
                    }}
                  >
                    Can’t find your address? Click here
                  </Link>
                </span>
              }
            />
            <IconButton onClick={() => setSuggestions([])}>
              <Close />
            </IconButton>
          </ListItem>
          <Divider />
          {(suggestions || []).map((address, index) => (
            <ListItem
              id={`SearchFieldAddress-ListItem${index}`}
              button
              classes={{ button: classes.item }}
              key={address.Id}
              onClick={() => selectSuggestion(address)}
            >
              <ListItemText
                primary={address.Text}
                secondary={address.Description}
              />
            </ListItem>
          ))}
        </div>
      </ContentBox>
    );
  };

  if (typeof onClear === 'function') {
    FieldProps.onClear = () => {
      setSearch('');
      onClear();
    };
  }

  return (
    <div className={className}>
      <TextField
        id={id}
        {...FieldProps}
        inputRef={inputEl}
        label={(FieldProps && FieldProps.label) || label}
        placeholder={placeholder}
        value={search}
        autoComplete="off"
        inputProps={{
          autoComplete: 'off',
        }}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        fullWidth
      />
      <Popper
        id={`AddressField-${Math.random()}`}
        open={suggestions && suggestions.length > 0}
        placement="bottom-start"
        anchorEl={inputEl && inputEl.current}
        disablePortal
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'sameWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width +
                16}px`;
              state.styles.popper.zIndex = 3;
              state.styles.popper.marginLeft = '-8px';
              state.styles.popper.marginTop = '4px';
            },
            effect: ({ state }) => {
              state.elements.popper.style.width = `${state.elements.reference
                .offsetWidth + 16}px`;
              state.elements.popper.style.zIndex = 3;
              state.elements.popper.style.marginLeft = '-8px';
              state.elements.popper.style.marginTop = '4px';
            },
          },
        ]}
      >
        {renderAddressSuggestions()}
      </Popper>
      <Modal open={show} onClose={closeModal} maxWidth={500}>
        <Modal.Title onClose={closeModal}>Enter your address</Modal.Title>
        <Modal.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" size="long">
                Type in your address below if it was not listed in the results.
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                required
                label={LABELS[form.country].number}
                value={form.number}
                onChange={handle('number')}
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                required
                fullWidth
                label="Street Name"
                value={form.street}
                onChange={handle('street')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Unit Number"
                value={form.unit}
                onChange={handle('unit')}
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                required
                fullWidth
                label="Country"
                value={form.country}
                data={COUNTRIES}
                onChange={handle('country')}
              />
            </Grid>
            {LABELS?.[form?.country]?.province && (
              <Grid item xs={12}>
                <Dropdown
                  required
                  fullWidth
                  label={LABELS[form.country].province}
                  value={form.province}
                  data={stateOptions}
                  onChange={handle('province')}
                />
              </Grid>
            )}
            {form.country === 'OT' && (
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Country Name"
                  value={form.countryName}
                  onChange={handle('countryName')}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label={LABELS[form.country].city}
                value={form.city}
                onChange={handle('city')}
              />
            </Grid>
            <Grid item xs={12}>
              {form.country !== 'US' ? (
                <TextField
                  required
                  fullWidth
                  label={LABELS[form.country].postal}
                  value={form.postal}
                  onChange={handle('postal')}
                />
              ) : (
                <TextFieldZip
                  required
                  fullWidth
                  label={LABELS[form.country].postal}
                  value={form.postal}
                  onChange={handle('postal')}
                />
              )}
            </Grid>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button secondary onClick={closeModal}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button disabled={!validForm} onClick={accept}>
                Use this address
              </Button>
            </Grid>
          </Grid>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default SearchFieldAddress;
