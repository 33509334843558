import { Suspense, lazy } from 'react';

// HoC
import { withRouter } from 'react-router';
import { makeStyles } from '@mui/styles';

// Components
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ChunkFailFallback from 'components/ChunkFailFallback';
import Loader from 'components/Loader';
import ImplicitCallback from 'components/ImplicitCallback';
import CacheBuster from 'components/CacheBuster';

// Hooks
import usePendo from 'hooks/usePendo';
import useSysend from 'hooks/useSysend';
import useFeatureFlags from 'hooks/useFeatureFlags';

// Providers
import AuthenticationProvider from 'context/Authentication/Provider';
import ConfigurationProvider from 'context/Configuration/Provider';
import FeatureFlagsProvider from 'context/FeatureFlags/Provider';
import SnackbarProvider from 'context/Snackbar/Provider';
import CountryProvider from 'context/Country/Provider';
import ScrollProvider from 'context/Scroll/Provider';
import MinorsProvider from 'context/Minors/Provider';
import ThemeProvider from 'context/Theme/Provider';
import LanguageProvider from 'context/Language/Provider';

// Pages
const Home = lazy(() => import('pages/Home'));
const Login = lazy(() => import('components/Login'));
const Login1099 = lazy(() => import('components/Login1099'));
const Register = lazy(() => import('pages/Register'));
const TokenCode = lazy(() => import('pages/UnpaidRegister/steps/TokenCode'));
const UnpaidRegister = lazy(() => import('pages/UnpaidRegister'));
const Support = lazy(() => import('pages/Support'));
const AccountCreation = lazy(() => import('pages/AccountCreation'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const OfferRegistration = lazy(() => import('pages/OfferRegistration'));
const ClientRegistration = lazy(() => import('pages/ClientRegistration'));
const FileManagementTest = lazy(() => import('pages/FileManagementTest'));
const ConfirmEmail = lazy(() => import('pages/ConfirmEmail'));

const FLAG_NET_NEW_UNPAID_USER = 'MyCnC-Net-New-Unpaid-User';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  '@global': {
    body: {
      backgroundColor: palette.cncUI.default.background,
      height: '100vh',
      width: '100vw',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    '.container': {
      height: '100%',
      width: '100%',
    },
    '.App': {
      height: '100%',
      width: '100%',
    },
    '.pendo-resource-center-badge-notification-bubble': {
      left: '22px !important',
      width: '14px !important',
      height: '14px !important',
      borderRadius: '16px !important',
      top: '0px !important',
      border: '1px solid #fff !important',
      [breakpoints.only('xs')]: {
        top: '4px !important',
        left: '24px !important',
      },
    },
    '.pendo-notification-bubble-unread-count': {
      fontSize: '10px !important',
      fontWeight: '500 !important',
      right: '-7px !important',
    },
  },
}));

const App = () => {
  useStyles();

  return (
    <AuthenticationProvider>
      <ConfigurationProvider>
        <SnackbarProvider>
          <FeatureFlagsProvider>
            <LanguageProvider>
              <MinorsProvider>
                <ScrollProvider>
                  <ThemeProvider>
                    <CacheBuster>
                      {({
                        loading,
                        isLatestVersion,
                        refreshCacheAndReload,
                      }) => {
                        usePendo();
                        useSysend();
                        const { isEnabled } = useFeatureFlags();

                        if (loading) return <Loader fullScreen withLogo />;
                        if (!loading && !isLatestVersion) {
                          refreshCacheAndReload();
                        }

                        return (
                          <div className="container">
                            <div className="App">
                              <ErrorBoundary
                                FallbackComponent={ChunkFailFallback}
                              >
                                <Suspense
                                  fallback={<Loader fullScreen withLogo />}
                                >
                                  <BrowserRouter
                                    getUserConfirmation={() => {
                                      /* Empty callback to block the default browser prompt */
                                    }}
                                  >
                                    <Switch>
                                      <Route
                                        path="/test-files"
                                        component={FileManagementTest}
                                      />
                                      <Route path="/login" component={Login} />
                                      <Route
                                        path="/1099/d61A9997bF24bEcA0aA631eCa90A0eEa/login"
                                        component={Login1099}
                                      />
                                      <Route
                                        path="/register"
                                        component={
                                          isEnabled(FLAG_NET_NEW_UNPAID_USER)
                                            ? UnpaidRegister
                                            : Register
                                        }
                                      />
                                      <Route
                                        path="/tokenCode"
                                        component={TokenCode}
                                      />
                                      <Route
                                        path="/client-registration"
                                        component={ClientRegistration}
                                      />
                                      <Route
                                        path="/support"
                                        component={Support}
                                      />
                                      <Route
                                        path="/welcome/:activationToken"
                                        component={AccountCreation}
                                      />
                                      <Route
                                        path="/reset-password"
                                        component={ResetPassword}
                                      />
                                      <Route
                                        path="/confirm-email"
                                        component={ConfirmEmail}
                                      />
                                      <Route
                                        path="/implicit/callback"
                                        exact
                                        component={ImplicitCallback}
                                      />
                                      <Route
                                        path="/startplusoffer"
                                        render={props => (
                                          <CountryProvider>
                                            <OfferRegistration {...props} />
                                          </CountryProvider>
                                        )}
                                      />
                                      <Route
                                        path="/startpluscanoffer"
                                        render={props => (
                                          <CountryProvider>
                                            <OfferRegistration {...props} />
                                          </CountryProvider>
                                        )}
                                      />
                                      <Route
                                        path="/etcoffer"
                                        render={props => (
                                          <CountryProvider>
                                            <OfferRegistration {...props} />
                                          </CountryProvider>
                                        )}
                                      />
                                      <Route
                                        render={props => (
                                          <CountryProvider>
                                            <Home {...props} />
                                          </CountryProvider>
                                        )}
                                      />
                                    </Switch>
                                  </BrowserRouter>
                                </Suspense>
                              </ErrorBoundary>
                            </div>
                          </div>
                        );
                      }}
                    </CacheBuster>
                  </ThemeProvider>
                </ScrollProvider>
              </MinorsProvider>
            </LanguageProvider>
          </FeatureFlagsProvider>
        </SnackbarProvider>
      </ConfigurationProvider>
    </AuthenticationProvider>
  );
};
export default withRouter(App);
