import { gql } from '@apollo/client';

export default gql`
  query getProject($id: String!) {
    getProject(id: $id) {
      id
      name
      loginId
      status
      values
      file
      config
      createdAt
      updatedAt
      updatedControl
      permissions
    }
  }
`;
