import { gql } from '@apollo/client';

export default gql`
  query getUserDashboard {
    getCurrentUser {
      settings
    }
    dashboard {
      user {
        address {
          addressLine1
          addressLine2
          addressLine3
          city
          state
          zipCode
          country
        }
        w4Documents {
          id
          documentId
          name
          country
          url
          stateId
          status
          createdAt
        }
        hasDirectDeposits
        loanout {
          createdAt
          dbCode
          corpStatus
          status
          isValid
          name
        }
      }
      lastUpdated {
        event
        date
      }
    }
  }
`;
