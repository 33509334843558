import { gql } from '@apollo/client';

export default gql`
  query getPaystubs($country: String) {
    getCurrentUser {
      paystubs(country: $country) {
        checkSequence
        checkNumber
        effectiveDate
        weekEndingDate
        checkGross
        checkNet
        prodcoName
        projectTitle
        workState
        workCountry
        dataSource
      }
    }
  }
`;
