import { red } from '@mui/material/colors';
import cncUI from './cncui';

export default {
  cncUI,
  dashboard: {
    white: '#FFFFFF',
    black: '#000000',
    background: '#f5f8ff',
    gray25: '#FCFCFD',
    gray50: '#F9FAFB',
    gray100: '#F2F4F7',
    gray200: '#EAECF0',
    gray300: '#D0D5DD',
    gray400: '#98A2B3',
    gray500: '#667085',
    gray600: '#465467',
    gray700: '#344054',
    gray800: '#182230',
    gray900: '#101828',
    gray950: '#0C111D',
    blue50: '#EFF4FF',
    blue300: '#84ADFF',
    blue600: '#155EEF',
    blue700: '#004EEB',
    success50: '#ECFDF3',
    success200: '#ABEFC6',
    success500: '#17B26A',
    success700: '#067647',
    error50: '#FEF3F2',
    error200: '#FECDCA',
    error500: '#F04438',
    error700: '#B42318',
    warning50: '#FFFAEB',
    warning200: '#FEDF89',
    warning500: '#F79009',
    warning700: '#B54708',
    blue: {
      light50: '#F0F9FF',
      light200: '#B9E6FE',
      light500: '#0BA5EC',
      light700: '#026AA2',
    },
    shadow: {
      modal:
        '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
    },
  },
  mycc: {
    white: '#fff',
    black: '#000',
    main: '#30aba3',
    dark: '#1D6762',
    light: '#eaf7f6',
    paper: '#f7fcfc',
    link: '#3c9dd7',
    button: '#4D7BFD',
    buttonDisabled: '#bfeeec',
    lightContrastText: '#83cdc8',
    outlineFormInput: '#268982',
    lightText: '#616161',
    box: '#f5fcfb',
    boxHeader: '#59bcb5',
    label: '#939393',
    formLabel: '#137e74',
    darkText: '#606060',
    error: '#F45656',
    success: '#acdcc0',
    fieldDisabled: '#999999',
    gray: '#e0e0e0',
    lightGray: '#f4f4f4',
    lightBG: '#f7fcfc',
    disableLabel: '#ababab',
    remove: '#b00020',
  },
  primary: {
    main: '#eaeded',
    light: '#eee',
    dark: '#b8bbbb',
    darkContrastText: '#4a4a4a',
    contrastText: '#888888',
  },
  secondary: {
    main: '#0000FF',
    light: '#4D7BFD',
    dark: '#0000B3',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#555',
    secondary: '#888',
  },
  error: red,
};
