export default {
  url: {
    search:
      'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json3.ws',
    get:
      'https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws',
  },
  params: {
    // Key: 'MW69-WM98-YW83-PD49', // old key
    Key: 'NN94-YN89-RD11-MG77',
    Language: 'en',
  },
};
